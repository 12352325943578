import {useEffect, useState} from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';

import {HouseApi} from 'api';
import {HouseConstructor} from 'shared/components/HouseConstructor';
import {HouseSettings} from 'shared/components/HouseConstructor/types';
import {initialHouseSettings, preparedEntries} from 'shared/components/HouseConstructor/utils';
import {routes} from 'shared/constants/routes';
import {useToast} from 'shared/hooks';

import styles from './EditHouse.module.scss';
import {getPreparedHouseSettings} from './utils';

export const EditHouse = () => {
  const [houseSettings, setHouseSettings] = useState<HouseSettings>(initialHouseSettings);
  const {apartmentComplexId, courtyardId, houseId} = useParams<{
    apartmentComplexId: string;
    courtyardId: string;
    houseId: string;
  }>();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {showToast} = useToast();

  const getHouse = async () => {
    if (apartmentComplexId && courtyardId && houseId) {
      try {
        setLoading(true);
        const house = await HouseApi.getEditingHouse(apartmentComplexId, courtyardId, houseId);
        setHouseSettings(getPreparedHouseSettings(house));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getHouse();
  }, [apartmentComplexId, houseId]);

  const onSave = async () => {
    if (apartmentComplexId && courtyardId && houseId) {
      try {
        setSaving(true);
        const {streetId, streetNumber, entries, quantityFloors, quantityApartmentsOnFloor} = houseSettings;
        const payload = {
          streetId,
          streetNumber,
          floorsCount: quantityFloors,
          apartmentsPerFloorCount: quantityApartmentsOnFloor,
          entries: preparedEntries(entries),
        };
        const editedHouse = await HouseApi.editHouse(apartmentComplexId, courtyardId, houseId, payload);
        if (editedHouse.buildingId) {
          navigate(generatePath(routes.apartments, {apartmentComplexId, courtyardId, houseId: editedHouse.buildingId}));
        }
        showToast({
          title: 'Дом отредактирован',
          message: 'Дом успешно отредактирован.',
        });
      } catch (err) {
        showToast({
          title: 'Дом не отредактирован',
          message: 'Не удалось отредактировать дом.',
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  const onCancel = () => {
    navigate(generatePath(routes.houses, {apartmentComplexId, courtyardId}));
  };

  return (
    <div className={styles.editHouse}>
      <HouseConstructor
        onCancel={onCancel}
        onSave={onSave}
        loading={loading}
        title={'Редактирование дома'}
        saving={saving}
        houseSettings={houseSettings}
        updateHouseSettings={setHouseSettings}
      />
    </div>
  );
};
