const apartmentComplexes = '/apartmentComplexes';
const courtyards = `${apartmentComplexes}/:apartmentComplexId`;
const gates = `${courtyards}/:courtyardId/gates`;
const houses = `${courtyards}/:courtyardId`;
const apartments = `${houses}/:houseId`;
const apartment = `${apartments}/:apartmentId`;
const intercoms = '/intercoms';
const intercom = `${intercoms}/:intercomId`;
const cameras = '/cameras';
const addNewCamera = `${cameras}/add`;
const employees = '/employees';
const addNewEmployees = `${employees}/add`;
const addNewIntercom = `${intercoms}/add`;
const accessDevices = '/accessDevices';
const accessDeviceCard = '/accessDevices/:accessDeviceId';
const parking = `${apartmentComplexes}/:apartmentComplexId/parking`;
const parkingSectionList = `${parking}/:parkingId`;
const addParkingSection = `${parkingSectionList}/add`;
const editParkingSection = `${parkingSectionList}/:parkingSectionId/edit`;
const parkingPlacesList = `${parkingSectionList}/:parkingSectionId`;
const parkingPlace = `${parkingPlacesList}/:parkingPlaceId`;

export const routes = {
  home: '/',
  apartmentComplexes,
  courtyards,
  gates,
  houses,
  apartments,
  apartment,
  intercoms,
  intercom,
  addNewIntercom,
  cameras,
  addNewCamera,
  employees,
  addNewEmployees,
  accessDevices,
  accessDeviceCard,
  parking,
  addParkingSection,
  editParkingSection,
  parkingPlacesList,
  parkingSectionList,
  parkingPlace,
  accessDenied: 'accessDenied',
  addHouse: `${houses}/add`,
  editHouse: `${houses}/:houseId/edit`,
  auth: '/login',
  camera: '/camera',
  activeApartments: '/activeApartments',
  activeParkingPlaces: '/activeParkingPlaces',
  offices: '/offices',
};
