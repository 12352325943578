export enum HouseConstructorTabs {
  first,
  second,
}

export type Floors = {
  id: number;
  floorNumber: number;
  floorType: number;
  quantityApartments: number;
  apartmentRange: string;
  apartmentNumbers: number[];
};

export type Entry = {
  id: number;
  entryNumber: number;
  quantityFloors: number;
  quantityApartmentsOnFloor: number;
  sameNumberApartments: boolean;
  firstApartmentNumber: number;
  lastApartmentNumber: number;
  floors: Floors[];
};

export type HouseSettings = {
  address: string;
  streetId: number | null;
  streetNumber: string;
  quantityEntries: number;
  quantityFloors: number;
  quantityApartmentsOnFloor: number;
  entries: Entry[];
};

export enum FloorType {
  residential,
  technical,
  commercial,
  office,
}
