import {LoadingButton} from '@mui/lab';
import dayjs from 'dayjs';
import {useState} from 'react';

import {OneApartmentApi} from 'api';
import {LocalizedDatePicker} from 'shared/components/LocalizedDatePicker';
import {useToast} from 'shared/hooks';
import {AccountRoles, ServiceModel, ServiceType} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import styles from './ServiceCard.module.scss';

const sn = bem('serviceCard', styles);

type Props = {
  services: ServiceModel;
  onAfterChangeService: () => void;
  id: string;
  title: string;
};

export const ServiceCard = ({services, onAfterChangeService, id, title}: Props) => {
  const [expirationDate, setExpirationDate] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);
  const {showToast} = useToast();

  const enableService = async () => {
    if (id && expirationDate) {
      try {
        setLoading(true);
        await OneApartmentApi.enableService(id, ServiceType.BaseFunctionsAccess, {expirationDate});
        if (onAfterChangeService) onAfterChangeService();
      } catch (err) {
        showToast({
          title: 'Ошибка',
          message: 'Ошибка подключения услуги',
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const disableService = async () => {
    if (id) {
      try {
        setLoading(true);
        await OneApartmentApi.disableService(id, ServiceType.BaseFunctionsAccess);
        setExpirationDate(null);
        if (onAfterChangeService) onAfterChangeService();
      } catch (err) {
        showToast({
          title: 'Ошибка',
          message: 'Ошибка отключения услуги',
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const onDateChange = (date: Date | string | null) => {
    const preparedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setExpirationDate(preparedDate);
  };

  return (
    <div className={styles.serviceCard}>
      <h4 className={sn('title')}>{title}</h4>
      <div className={sn('panel')}>
        <div className={sn('columns')}>
          <div className={sn('column')}>
            <span>Услуга {services.isActive ? 'подключена' : 'отключена'}</span>
            {services.isActive && <span>{services.startDate}</span>}
          </div>
          <div className={sn('column')}>
            <span>{services.isActive ? 'Услуга активна до' : 'Выберите дату окончания услуги'}</span>
            <span>
              <LocalizedDatePicker
                value={services.isActive ? dayjs(services.expirationDate).toDate() : null}
                onDateChange={onDateChange}
                disabled={services.isActive}
              />
            </span>
          </div>
        </div>
        <LoadingButton
          variant="contained"
          color="secondary"
          loading={loading}
          className={sn('button')}
          disabled={isDisabled || (!services.isActive && !expirationDate)}
          onClick={services.isActive ? disableService : enableService}
        >
          {services.isActive ? 'Отключить' : 'Подключить'}
        </LoadingButton>
      </div>
    </div>
  );
};
