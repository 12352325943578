export enum ServiceType {
  BaseFunctionsAccess,
}

export interface ServiceModel {
  type: ServiceType;
  name: string;
  startDate: string;
  expirationDate: string;
  isActive: true;
}

export interface OneApartmentModel {
  apartmentId: number;
  apartmentNumber: number;
  floorNumber: number;
  tenants: OneApartmentTenants[];
  services: ServiceModel[];
}

export interface OneApartmentTenants {
  clientId: number;
  fullName: string;
  createDate: string;
  phoneNumber: string;
  email: string;
  isActive: boolean;
  isOwner: boolean;
}

export type EnableServicePayload = {
  expirationDate: string;
}
