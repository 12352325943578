import {useState} from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';

import {HouseApi} from 'api';
import {HouseConstructor} from 'shared/components/HouseConstructor';
import {HouseSettings} from 'shared/components/HouseConstructor/types';
import {initialHouseSettings, preparedEntries} from 'shared/components/HouseConstructor/utils';
import {routes} from 'shared/constants/routes';
import {useToast} from 'shared/hooks';

import styles from './AddNewHouse.module.scss';

export const AddNewHouse = () => {
  const [houseSettings, setHouseSettings] = useState<HouseSettings>(initialHouseSettings);
  const {apartmentComplexId, courtyardId} = useParams<{apartmentComplexId: string; courtyardId: string}>();
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const {showToast} = useToast();

  const onSave = async () => {
    if (apartmentComplexId && courtyardId) {
      try {
        setSaving(true);
        const {streetId, streetNumber, entries, quantityApartmentsOnFloor, quantityFloors, address} = houseSettings;
        const payload = {
          streetId,
          streetNumber,
          floorsCount: quantityFloors,
          apartmentsPerFloorCount: quantityApartmentsOnFloor,
          entries: preparedEntries(entries),
        };
        const createdHouse = await HouseApi.addHouse(apartmentComplexId, courtyardId, payload);
        if (createdHouse.buildingId) {
          navigate(
            generatePath(routes.apartments, {apartmentComplexId, courtyardId, houseId: createdHouse.buildingId}),
          );
        }
        showToast({
          title: 'Дом добавлен',
          message: `Дом ${streetNumber} по адресу ${address} успешно добавлен.`,
        });
      } catch (err) {
        showToast({
          title: 'Дом не добавлен',
          message: 'Не удалось добавить дом.',
          isError: true,
        });
      } finally {
        setSaving(false);
      }
    }
  };

  const onCancel = () => {
    navigate(generatePath(routes.houses, {apartmentComplexId, courtyardId}));
  };

  return (
    <div className={styles.addNewHouse}>
      <HouseConstructor
        onCancel={onCancel}
        onSave={onSave}
        title={'Добавление дома'}
        saving={saving}
        houseSettings={houseSettings}
        updateHouseSettings={setHouseSettings}
      />
    </div>
  );
};
