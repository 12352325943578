import axios, {Axios} from 'axios';

import {setTokenToLS} from 'shared/utils/tokensHandlers';
import {store} from 'store';
import {setAuth} from 'store/authStore';

import {authProvider, tokenRefreshService} from '../utils';

export function registerAuthInterceptor(api: Axios) {
  api.interceptors.request.use(async (req) => {
    const accessToken = authProvider.getToken();
    const fingerprint = await authProvider.getFingerprint();
    if (!!accessToken && req.headers && !req.headers?.Authorization) {
      req.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (req.headers && !req.headers?.Fingerprint) {
      req.headers.Fingerprint = fingerprint;
    }
    return req;
  });
}

export function registerErrorHandlingInterceptor(api: Axios) {
  api.interceptors.response.use(
    async (res) => res,
    async (error) => {
      const config = error.config;
      const isDisabledNotification = config.data === 'disable notification';

      if (error.response.status === 401 && !config._retryRefresh) {
        config.headers.Authorization = null;
        config._retryRefresh = true;
        try {
          const {accessToken} = await tokenRefreshService.refreshTokens();
          setTokenToLS(accessToken);
          config.headers.Authorization = `Bearer ${accessToken}`;
        } catch (err) {
          store.dispatch(setAuth(false));
          throw err;
        }
        return axios(config);
      }

      if (error.response?.config?.data) {
        return Promise.reject(error);
      }

      if (error.response) {
        const data = error.response.data;
        let text = '';
        if (data?.title) {
          text = data.title;
        }
        if (data?.message) {
          text = data.message;
        }
        if (text && !isDisabledNotification) {
          // TODO: add toast
        }
        throw new Error(error);
      } else {
        return Promise.reject(error);
      }
    },
  );
}
