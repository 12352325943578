import {routes} from 'shared/constants/routes';
import {AccountRoles} from 'shared/models';

export type NavMenuSubItem = {
  name: string;
  route: string;
  accessRoles: AccountRoles[];
};

export type NavMenu = {
  name: string;
  subItems: NavMenuSubItem[];
};

export const NAV_MENU: NavMenu[] = [
  {
    name: 'Объекты',
    subItems: [
      {
        name: 'Жилые Комплексы',
        route: routes.apartmentComplexes,
        accessRoles: [AccountRoles.admin, AccountRoles.superAdmin, AccountRoles.user],
      },
    ],
  },
  {
    name: 'Оборудование',
    subItems: [
      {
        name: 'Домофоны',
        route: routes.intercoms,
        accessRoles: [AccountRoles.admin, AccountRoles.superAdmin],
      },
      {
        name: 'Камеры',
        route: routes.cameras,
        accessRoles: [AccountRoles.admin, AccountRoles.superAdmin],
      },
      {
        name: 'Устройства доступа',
        route: routes.accessDevices,
        accessRoles: [AccountRoles.admin, AccountRoles.superAdmin],
      },
    ],
  },
  {
    name: 'Пользователи',
    subItems: [
      {
        name: 'Сотрудники Регион',
        route: routes.employees,
        accessRoles: [AccountRoles.admin, AccountRoles.superAdmin],
      },
      {
        name: 'Офисы',
        route: routes.offices,
        accessRoles: [AccountRoles.admin, AccountRoles.superAdmin],
      },
    ],
  },
  {
    name: 'Отчеты',
    subItems: [
      {
        name: 'Активные квартиры',
        route: routes.activeApartments,
        accessRoles: [AccountRoles.admin, AccountRoles.superAdmin, AccountRoles.user],
      },
      {
        name: 'Активный паркинг',
        route: routes.activeParkingPlaces,
        accessRoles: [AccountRoles.admin, AccountRoles.superAdmin, AccountRoles.user],
      },
    ],
  },
];
